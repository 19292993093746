import React, { useState, useContext } from "react"; 
import { AuthContext, AuthProvider } from "../../components/AuthProvider"; 
import ErrorComponent from '../../components/Form/ErrorComponent';

//this component exists just to be wrapped in the auth layout and have access to the login functtion
//is is otherwise not needed
function SuccessPinCodeFormComponent({ username }) {


  const { login, loginErrors, requestPinCode, spinner, loggingInStatus } = useContext(AuthContext);
  const [pin, setPin] = useState('')

  return (
    <AuthProvider>

      <form className="max-w-md mx-auto pt-3 mt-3" onSubmit={(e) => { e.preventDefault(); login(username, pin); }}>
        <span className="font-semibold text-lg text-center block">{username}</span>
        <p className="text-gray-700 text-center">
          We just sent you a temporary pin code. Please check your inbox.
        </p>
        <div className={`my-4 ${loginErrors && loginErrors.length > 0 ? ' input-error' : ''}`}>
          <label htmlFor="email" className="sr-only">
            Pin Code
          </label>
          <input
            type="text"
            id="pin"
            onChange={(e) => setPin(e.target.value)}
            className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-100 rounded-md py-3"
            placeholder="Pin Code"
            value={pin}
          />
        </div>

        {loginErrors && loginErrors.length > 0 ? loginErrors.map((error,e)=><div className="mb-4"><ErrorComponent  key={e} message={error.message}/></div>) : null}
   
        <button type="submit" value="Submit"
          className="font-display flex  justify-center w-full items-center px-4 py-3 border border-transparent text-base mt-2 font-semibold rounded-md shadow-sm text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          {spinner ? <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg> : ''} {loggingInStatus ? loggingInStatus : "Login"}
        </button>

      </form>
    </AuthProvider>
  );
}

export default SuccessPinCodeFormComponent;
